import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import getI18n from "./i18n";

Vue.config.productionTip = false

// const updateSizes = (obj = {}) => {
//   obj.width = window.innerWidth
//   obj.height = window.innerHeight
//   return obj
// }
// window.addEventListener('resize', () => {
//   updateSizes(Vue.prototype.$viewport)
// })

class WindowSizeManager {
  constructor() {
  }

  get height () {
    return window.innerHeight;
  }

  get width () {
    return window.innerWidth;
  }
}

const sizeManager = new WindowSizeManager()

Object.defineProperty(Vue.prototype, '$viewport', {
  get: () => {
    return Vue.observable(sizeManager)
  }
})

async function main () {
  const i18n = await getI18n()
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

main()



