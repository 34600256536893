<template>
  <div>
    <header-main/>
    <main-body>
      <HomeMainBody/>
    </main-body>
    <secondary-block>
      <partners/>
    </secondary-block>
    <partners-and-consulting/>
  </div>
</template>

<script>
  import HomeMainBody from "@/components/HomeMainBody";
  import mainBody from "../components/MainBody";
  import SecondaryBlock from "@/components/SecondaryBlock";
  import Partners from "@/components/Partners";
  import PartnersAndConsulting from "@/components/PartnersAndConsulting";
  import HeaderMain from "@/components/HeaderMain";
  export default {
    name: 'Home',

    components: {
      HeaderMain,
      PartnersAndConsulting,
      Partners,
      SecondaryBlock,
      HomeMainBody,
      mainBody
    },
  }
</script>