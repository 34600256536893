import FileLoader from "./FileLoader";

export default class IssueManager {
  constructor() {
    this._complexData = {}
  }
   async LoadIssues () {
     const fileLoader = new FileLoader()
     const [allJournals, complexData] = await Promise.all([
       fileLoader.Load('journals.json'),
       fileLoader.Load('complex.json')
     ])
     this._complexData = complexData
     return allJournals.reduce((acc, val) => {
       if (!acc[val.year]) acc[val.year] = []
       acc[val.year].push(val)
       return acc
     }, {})

  }

  GetIssueLink (journal) {
    if (!journal) return
    if (journal.directLink) {
      return {
        link: journal.directLink,
        directLink: true
      }
    } else {
      const dataPath = `${journal.year}.${journal.vol}.${journal.is}`
      if (this._complexData[dataPath]) {
        return {
          link: `/issues/${dataPath.replaceAll('.', '/')}`,
          directLink: false
        }
      }
    }
  }
}
