<template>
  <v-row justify="center" class="partners-and-consulting-main">
    <v-col cols="4">
      <h3>
        {{$t('partnersAndConsulting')}}
      </h3>
      <p>
        {{$t('socialCenter')}}
      </p>
      <p>
        {{$t('journal')}}
        <br>
        {{$t('faesj')}}
      </p>
    </v-col>
    <v-col cols="4">
      <h3 class="idsInCrossRef">
        {{$t('idsInCrossRef')}}
      </h3>
      <v-img src="../../img/crossref.png" alt="crossref" class="crossref" max-width="400" max-height="192"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PartnersAndConsulting"
}
</script>

<style scoped>
.partners-and-consulting-main{
  margin-top: 2%;
  margin-bottom: 2%;
}
.idsInCrossRef{
  text-align: center;
  margin-bottom: 5%;
}
.crossref{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>