<template>
  <v-footer
      absolute
      app
      dark
      color="primary">
    <v-row justify="center">
      <v-col cols="4">
        <p class="footer-block">
          <strong>
            {{$t('redactionAddress') + ':'}}
          </strong>
          <br>
          <br>
          {{$t('ufj')}}
          <br>
          <span v-html="$t('nuft')"/>
          <br>
          {{$t('address')}}
          <br>
          {{$t('kyiv') + ', ' + $t('postalCode')}}
          <br>
          {{$t('ukraine')}}
        </p>
      </v-col>

      <v-col cols="4">
        <p class="footer-block">
          <strong>
            {{$t('executiveSecretary') + ':'}}
          </strong>
          <br>
          <br>
          {{$t('gubenya')}}
          <br>
          <span v-html="$t('nuft')"/>
          <br>
          <br>
          E-mail: Ukrfoodscience@meta.ua
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterMain",
  components: {}
}
</script>

<style scoped>
.footer-block{
  margin: 3% auto;
  width: 70%;
  padding: 1.5%;
}
</style>
