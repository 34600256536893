import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/editorialBoard',
    name: 'Editorial Board',
    component: () => import('../views/Editors.vue')
  },
  {
    path: '/authorInstructions',
    name: 'Instructions for authors',
    component: () => import('../views/Instructions.vue')
  },
  {
    path: '/ethicsAndCopyright',
    name: 'Ethics and copyright',
    component: () => import('../views/Ethics.vue')
  },
  {
    path: '/issues/:year/:vol/:is',
    name: 'Issues',
    component: () => import('../views/Issues.vue')
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
