<template>
  <div v-if="getCustomContent" class="partners-body">
    <span v-html="getCustomContent"/>
  </div>
  <div v-else class="partners-body">
    <h2 class="partners-body-header">
      {{$t('indexedBy')}}
    </h2>
    <v-row justify="center" class="partners-row">
      <img src="img/Google_Scholar.png" class="col col-2" style="width: 250px; height: 200px">
    </v-row>
    <v-row justify="center" class="partners-row">
      <img src="img/cas.png" class="col col-2" style="width: 250px; height: 200px">
      <img src="img/fsta.png" class="col col-2" style="width: 250px; height: 200px">
      <img src="img/OpenAccessButtonLogo.png" class="col col-2" style="width: 250px; height: 200px">
      <img src="img/ebsco.png" class="col col-2" style="width: 250px; height: 200px">
    </v-row>
  </div>
</template>

<script>
import config from "../../config.json";

export default {
  name: "Partners",

  data: () => ({
    customContentua: '',
    customContenten: ''
  }),

  computed: {
    getCustomContent () {
      if (this._i18n.locale === 'en') {
        return this.customContenten
      }

      return this.customContentua
    }
  },

  async mounted() {
    for (const lang of ['en', 'ua']) {
      try {
        const response = await fetch(`${config.NUFT_URL}/rawPages/partners_${lang}.html`)
        if (response.status !== 200) {
          continue;
        }
        this[`customContent${lang}`] = await response.text()
      } catch (e) {
        console.log("No file")
      }
    }
  }
}
</script>

<style scoped>
.partners-row{
  height: 200px;
}
.partners-body{
  margin-bottom: 2%;
  margin-top: 0.5%;
}
.partners-body-header{
  #color: #999;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 2%;
}
</style>