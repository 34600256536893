<template>
  <v-row align="middle" justify="center" class="secondary-block-root">
    <v-col cols="12">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SecondaryBlock"
}
</script>

<style scoped>
.secondary-block-root{
  background-color: rgba(170, 226, 255, 0.2);
  margin-top: 2%;
  margin-bottom: 2%;
}
</style>