<template>
  <div>
    <v-row class="computer header" justify="center">
      <v-col cols="2">
        <v-row>
          <v-col>
            <img src="../assets/ufjBook.jpg" alt="ufj" class="imgtitul">
          </v-col>
          <v-col class="booktext">
            ISSN 2409-4951
            <br>
            (Online)
            <br><br>
            ISSN 2310-1008
            <br>(Print)
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <h1 class="header-center">
          {{$t('ufj')}}
        </h1>
      </v-col>
      <v-col cols="2" class="header-right">
        {{$t('founder') + ':'}}
        <br>
        <br>
        <strong v-html="$t('nuft')" />
        <br>
        {{`${$t('kyiv')}, ${$t('ukraine')}`}}
      </v-col>
    </v-row>

    <v-row class="mobilka" justify="center">
      <v-col cols="11">
        <v-row justify="center">
          <v-col cols="4">
            <img src="../assets/ufjBook.jpg" alt="ufj" class="imgtitul">
          </v-col>
          <v-col cols="2" class="booktext">
            ISSN 2409-4951
            <br>
            (Online)
            <br><br>
            ISSN 2310-1008
            <br>(Print)
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mobilka">
      <v-col cols="11">
        <h4 style="font-size: 22px" class="header-center">
          {{$t('ufj')}}
        </h4>
      </v-col>
    </v-row>
    <v-row class="mobilka">
      <v-col cols="11" style="text-align: center">
        {{$t('founder') + ':'}}
        <br>
        <br>
        <strong v-html="$t('nuft')" />
        <br>
        {{`${$t('kyiv')}, ${$t('ukraine')}`}}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HeaderMain"
}
</script>

<style scoped>
.header-center {
  display: block;
  font-size: 45px;
  width: 285px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.header-right{
  text-align: end;
}
.imgtitul {
  width: 110px;
  height: 142px;
}
.header{
  padding-top: 2%;
  margin-bottom: 2%;
}
.no-margin{
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.booktext {
  font-size: 13px;
  font-weight: bold;
}
@media (max-width: 850px) {
  .computer {
    display: none;
  }
  .mobilka {
    display: flex;
  }
}
@media (min-width: 850px) {
  .computer {
    display: flex;
  }
  .mobilka {
    display: none;
  }
}
</style>
