<template>
  <div class="issues-block">
    <a
      v-for="(journal, index) in journals.filter(j => j.visible)"
      :key="index"
      @click="$emit('click', journal)"
      class="journal-item">
      {{journal.title}}
      <br>
    </a>
  </div>
</template>

<script>
export default {
  name: "IssuesBlock",
  props: {
    journals: {
      type: Array,
      default: () => []
      /**
       {
        title: '',
        directLink: '',
        year: 0,
        vol: 0,
        is: 0,
        visible: false
      }
      */
    },
  }
}
</script>

<style scoped>
.issues-block{
  margin-top: 10%;
}
.journal-item{
  text-decoration: none;
}
a:hover {
  font-weight: bold;
}
</style>