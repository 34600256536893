import Vue from 'vue'
import VueI18n from 'vue-i18n'
import FileLoader from "./utils/FileLoader";

Vue.use(VueI18n)

async function loadLocaleMessages () {
  const fileLoader = new FileLoader()
  // eslint-disable-next-line no-debugger
  const [en, ua] = await Promise.all([
    fileLoader.Load('en.json'),
    fileLoader.Load('ua.json'),
  ])
  console.dir({ ua, en })
  return {
    en,
    ua
  }
}
let instance = null
async function getI18n() {
  if (instance) return instance
  instance = new VueI18n({
    locale: localStorage.locale || process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ua',
    messages: await loadLocaleMessages()
  })
  return instance
}

export default getI18n
