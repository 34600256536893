<template>
  <v-row justify="center">
    <v-col id="main-body" cols="6">
      <slot />
    </v-col>
    <v-col
      id="issue-archive"
      lg="1"
      md="1"
    >
      <issues-archive/>
    </v-col>
  </v-row>
</template>

<script>
import issuesArchive from "./IssuesArchive";
export default {
  name: "MainBody",
  components: { issuesArchive },
  computed: {
    isMobile () {
      const { year, vol, is } = this.$route.params
      return this.contentData[`${year}.${vol}.${is}`] || []
    },
    width () {
      return this.$viewport.width
    }
  },
}


</script>

<style scoped>
@media (max-width: 1000px) {
  #issue-archive {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  #main-body {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (max-width: 850px) {
  #main-body {
    flex: 0 0 90%;
    max-width: 90%;
  }
  #issue-archive {
    display: none;
  }
}

</style>
