<template>
  <div class="side-bar">
    <strong>
      <h3 class="side-bar-title">{{$t('sideBarTitle')}}</h3>
    </strong>
    <div class="issues-block">
      <issues-block
        v-for="(journals, index) in allJournals"
        :key="index"
        :journals="journals"
        @click="loadIssue"
      />
    </div>
  </div>
</template>

<script>
import IssuesBlock from "@/components/IssuesBlock";
import IssueManager from "../utils/IssueManager"
export default {
  name: "IssuesArchive",
  components: {IssuesBlock},
  data: () => ({
    allJournals: Array,
    issueManager: new IssueManager()
  }),

  async created () {
    const journalSorted = await this.issueManager.LoadIssues();
    this.allJournals = Object.values(journalSorted)
  },

  methods: {
    loadIssue(journal) {
      if (!journal) return
      const { link, directLink } = this.issueManager.GetIssueLink(journal)
      if (directLink) {
        window.location.href = link
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style scoped>
.side-bar-title {
  margin-top: 2%;
  display: block;
  text-align: right;
}
.issues-block{
  text-align: right;
}
.side-bar{
  margin-top: 10%;
}
</style>
