<template>
  <div>
    <v-app-bar
      class="centered-bar"
      app
      color="primary"
      elevate-on-scroll
      dark
    >
      <v-spacer class="computer" />

      <v-row justify="center" class="computer" align="center">
        <v-col class="computer" cols="9">
          <v-btn class="nav-button" to="/" text>
            {{$t('mainPage')}}
          </v-btn>
          <v-btn class="nav-button" to="/editorialBoard" text>
            {{$t('editorialBoard')}}
          </v-btn>
          <v-btn class="nav-button" to="/authorInstructions" text>
            {{$t('authorInstructions')}}
          </v-btn>
          <v-btn class="nav-button" to="/ethicsAndCopyright" text>
            {{$t('ethicsAndCopyright')}}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="1" align="center">
          <v-btn @click="changeLanguage" text>
            {{languageToChange}}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mobilka">
        <v-col cols="3">
          <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
              class="mobilka"
          />
        </v-col>
        <v-spacer />
        <v-col cols="3" style="text-align: end">
          <v-btn style="padding-left: 0; padding-top: 11px" @click="changeLanguage" text>
            {{languageToChange}}
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      hide-overlay
    >
      <v-list
        nav
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"

        >
          <v-list-item to="/">
            <v-list-item-title>{{$t('mainPage')}}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/editorialBoard">
            <v-list-item-title>{{$t('editorialBoard')}}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/authorInstructions">
            <v-list-item-title>{{$t('authorInstructions')}}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/ethicsAndCopyright">
            <v-list-item-title>{{$t('ethicsAndCopyright')}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-divider />

        <v-list-item-group
         v-model="issue"
        >
          <v-list-item
            v-for="(journal, index) in allJournals"
            :key="index"
            @click="loadIssue(journal)"
          >
            <v-list-item-title>{{ journal.title }}</v-list-item-title>
          </v-list-item>
          <v-divider />
        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import IssueManager from "../utils/IssueManager";

export default {
  name: "TopNavBar",
  data: () => ({
    drawer: false,
    group: null,
    issue: null,
    allJournals: [],
    issueManager: new IssueManager()
  }),
  computed: {
    languageToChange () {
      return this._i18n.locale === 'ua' ? 'en' : 'ua'
    }
  },

  async created() {
    const journalSorted = await this.issueManager.LoadIssues()
    this.allJournals = Object.values(journalSorted).reduce((acc, val) => [...acc, ...val],[])
  },

  methods: {
    changeLanguage () {
      localStorage.locale = this.languageToChange
      this._i18n.locale = this.languageToChange
    },

    loadIssue(journal) {
      if (!journal) return
      const { link, directLink } = this.issueManager.GetIssueLink(journal)
      if (directLink) {
        window.location.href = link
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style scoped>
.nav-button {
  margin: 0.3%;
}
.centered-bar{
  margin-top: 2%;
}
@media (max-width: 850px) {
  .computer {
    display: none;
  }
  .mobilka {
    display: flex;
  }
}
@media (min-width: 850px) {
  .computer {
    display: flex;
  }
  .mobilka {
    display: none;
  }
}
</style>
