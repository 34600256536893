<template>
  <v-app>
    <top-nav-bar class="centered-bar"/>
    <v-main>
      <router-view/>
    </v-main>
    <footer-main class="centered-bar"/>
  </v-app>
</template>

<script>
import FooterMain from "@/components/FooterMain";
import TopNavBar from "@/components/TopNavBar";
export default {
  name: 'App',
  components: {TopNavBar, FooterMain },
  data: () => ({
  }),
};
</script>

<style>
.centered-bar{
  align-content: center;
  display: block;
  margin: 0 auto;
}
</style>
