const config = require('../../config.json')
class FileLoader {
  constructor() {
    switch (config.MODE){
      case 'DEBUG':
        this.loader = new LocalLoader()
        break
      case 'RELEASE':
        this.loader = new ServerLoader()
        break
      default:
        this.loader = new ServerLoader()
    }
  }
  async Load(fileName) {
    return this.loader.Load(fileName)
  }
}

class LocalLoader {
  Load(fileName) {
    const files = require(`../../public/jsons/${fileName}`)
    if (files) {
      return files
    }
    return null
  }
}

class ServerLoader {
  async Load (fileName) {
    const url = config.NUFT_URL
    const files = await fetch(`${url}/jsons/${fileName}`)
    const data = await files.json()
    if (!data) {
      throw new Error(`File ${fileName} on load error. Path: ${url}/jsons/${fileName}`)
    }
    return data
  }
}
export default FileLoader
