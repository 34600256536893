<template>
  <div v-if="getCustomContent">
    <span v-html="getCustomContent"/>
  </div>
  <div v-else>
    <h1 class="home-main-header">
      {{$t("aboutUfj")}}
    </h1>
    <div class="home-main-body">
      <p>
        <strong>{{$t("ufj")}}</strong>
        {{$t("homePage.part1")}}
      </p>
      <p>
        {{$t("homePage.part2")}}
      </p>
      <h4>
        {{$t("homePage.part3")}}
      </h4>
      <v-row>
        <v-col cols="6">
          {{$t('homePage.part4.theme1')}}
          <br>
          {{$t('homePage.part4.theme2')}}
          <br>
          {{$t('homePage.part4.theme3')}}
          <br>
          {{$t('homePage.part4.theme4')}}
          <br>
          {{$t('homePage.part4.theme5')}}
          <br>
        </v-col>
        <v-col cols="6">
          {{$t('homePage.part4.theme6')}}
          <br>
          {{$t('homePage.part4.theme7')}}
          <br>
          {{$t('homePage.part4.theme8')}}
          <br>
          {{$t('homePage.part4.theme9')}}
          <br>
          {{$t('homePage.part4.theme10')}}
          <br>
        </v-col>
      </v-row>
      <br>
      <p>
        <strong>{{$t("homePage.part5")}}</strong>
        <br>
        {{$t("homePage.part6")}}
      </p>
      <p>
        <strong>{{$t("homePage.part7")}}</strong>
        <br>
        {{$t("homePage.part8")}}
      </p>
      <strong>{{$t("homePage.part9")}}</strong>
      <p v-html="$t('homePage.part10')"> </p>
      <p>
        <strong>{{$t("homePage.part11")}}</strong>
        <br>
        {{$t("homePage.part12")}}
      </p>
      <router-link to="ethicsAndCopyright">
        {{$t("homePage.part13")}}
      </router-link>
    </div>
  </div>
</template>

<script>
import config from "../../config.json";

export default {
  name: "HomeMainBody",
  components: {},

  data: () => ({
    customContentua: '',
    customContenten: ''
  }),

  computed: {
    getCustomContent () {
      if (this._i18n.locale === 'en') {
        return this.customContenten
      }

      return this.customContentua
    }
  },

  async mounted() {
    for (const lang of ['en', 'ua']) {
      try {
        const response = await fetch(`${config.NUFT_URL}/rawPages/home_${lang}.html`)
        if (response.status !== 200) {
          continue;
        }
        this[`customContent${lang}`] = await response.text()
      } catch (e) {
        console.log("No file")
      }
    }
  }
}
</script>

<style scoped>
.home-main-partners{
  background-color: #f9f9f9;
}
.home-main-header{
  margin-top: 3%;
  margin-bottom: 3%;
}
</style>